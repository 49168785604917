import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Email, Phone, PinMap, WhatsappStroke } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

export interface Props {
  address?: string
  email?: string
  languageCode: string
  mainPhone?: string
  phone?: string
  siteName?: string
  whatsapp?: string
}

export const ContactsIntro = memo(function ContactsIntro({
  address,
  email,
  languageCode,
  mainPhone,
  phone,
  siteName,
  whatsapp,
}: Props) {
  return (
    <Container>
      {siteName ? (
        <FadeInUp>
          <SiteName>{siteName}</SiteName>
        </FadeInUp>
      ) : null}
      <FadeInUp>
        <Wrapper dial={2} row wrap>
          {address ? (
            <Item className="contacts-address">
              <PinMap />
              <Label>{useVocabularyData('address', languageCode)}</Label>
              <Address dangerouslySetInnerHTML={{ __html: address }} />
            </Item>
          ) : null}
          {mainPhone || phone ? (
            <Item>
              <Phone />
              <Label>{useVocabularyData('telephone', languageCode)}</Label>
              {mainPhone ? (
                <Link
                  href={`tel:${mainPhone}`}
                  onClick={() => {
                    if (isMobile) {
                      typeof window.gtag !== 'undefined' &&
                        window.gtag('event', 'click', {
                          event_category: 'Website',
                          event_label: 'Phone Number',
                        })
                    }
                  }}
                >
                  {mainPhone}
                </Link>
              ) : null}
              {phone ? (
                <>
                  <Divider />
                  <Link
                    href={`tel:${phone}`}
                    onClick={() => {
                      if (isMobile) {
                        typeof window.gtag !== 'undefined' &&
                          window.gtag('event', 'click', {
                            event_category: 'Website',
                            event_label: 'Phone Number',
                          })
                      }
                    }}
                  >
                    {phone}
                  </Link>
                </>
              ) : null}
            </Item>
          ) : null}
          {whatsapp ? (
            <Item>
              <WhatsappStroke />
              <Label>{useVocabularyData('whatsapp', languageCode)}</Label>
              <Link href={`https://api.whatsapp.com/send?phone=${whatsapp}`}>
                {whatsapp}
              </Link>
            </Item>
          ) : null}
          {email ? (
            <Item>
              <Email />
              <Label>{useVocabularyData('email', languageCode)}</Label>
              {email ? (
                <Link
                  href={`mailto:${email}`}
                  onClick={() => {
                    typeof window.gtag !== 'undefined' &&
                      window.gtag('event', 'click', {
                        event_category: 'Website',
                        event_label: 'Email Address',
                      })
                  }}
                >
                  {email}
                </Link>
              ) : null}
            </Item>
          ) : null}
        </Wrapper>
      </FadeInUp>
    </Container>
  )
})

const Container = styled.section`
  margin: 9rem 0 8.75rem;
  padding: 0 3.333vw;
  text-align: center;

  @media (max-width: 1023px) {
    margin: 5rem 0 7.5rem;
    padding: 0 1.875rem;
  }

  @media (max-width: 767px) {
    margin-bottom: 5rem;
  }
`

const SiteName = styled.h2`
  max-width: 18.75rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin: 0 auto 5.25rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    margin-bottom: 0;
  }
`

const Wrapper = styled(FlexBox)`
  max-width: 64rem;
  margin: auto;

  @media (max-width: 1023px) {
    max-width: none;
  }
`

const Item = styled.div`
  width: 25%;
  &.contacts-address {
    svg {
      fill: none;
      stroke: ${({ theme }) => theme.colors.variants.primaryLight3};
      stroke-width: 2;
    }
  }

  svg {
    width: auto;
    height: 1.875rem;
    fill: ${({ theme }) => theme.colors.variants.primaryLight3};
    margin-bottom: 1.125rem;
  }

  @media (max-width: 1023px) {
    width: 50%;
    margin-top: 3rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 1.875rem;
    svg {
      display: none;
    }
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.0094rem;
  line-height: 1.1875rem;
  margin-bottom: 1.125rem;
  text-transform: uppercase;
`

const Divider = styled.div``

const Address = styled.div`
  color: ${({ theme }) => theme.colors.variants.secondaryDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.1875rem;
`

const Link = styled.a`
  color: ${({ theme }) => theme.colors.variants.secondaryDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.1875rem;
  &:hover {
    text-decoration: underline;
  }
`
