import styled from '@emotion/styled'
import { Spinner } from 'app/components/Common/Spinner'
import React, { memo } from 'react'

export interface Props {
  description?: string
  languageCode: string
  title?: string
}

export const ContactsForm = memo(function ContactsForm({
  description,
  title,
}: Props) {
  return (
    <Container>
      {title ? <Title>{title}</Title> : null}

      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}

      <Wrapper>
        <Spinner />

        <ConvertoForm id="converto" />
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 14rem auto 12.5rem;
  padding: 0 2.25rem;
  text-align: center;

  @media (max-width: 1199px) {
    margin: 10rem auto 7.5rem;
    padding: 0 1.5rem;
  }
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.primaryLight3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin-bottom: 1.625rem;
`

const Description = styled.div`
  max-width: 35.625rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 300;
  line-height: 2rem;
  margin: auto;
`

const Wrapper = styled.div`
  position: relative;
  text-align: left;
`

const ConvertoForm = styled.div`
  max-width: 49.25rem;
  min-height: 41.875rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin: 3.75rem auto 0;
  position: relative;
  z-index: 11;

  @media (max-width: 1199px) {
    max-width: none;
    margin-right: -1.25rem;
    margin-left: -1.25rem;
  }
`
